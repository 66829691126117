








import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import Can from '@/shared/components/Can.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Project from '@/shared/modules/project/models/project.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import moment from 'moment'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { RoleLevel } from '@/shared/modules/role/role.model'
import { CompanyRoutes } from '@/shared/router/company'

@Component({
    components: { Can, PageHeader, Form },
    methods: { __ }
  })
  export default class ToolsEdit extends Vue {
    form: FormBase = null!

    created() {
      this.form = new FormBase()
        .setEndpoint(`company/${ this.company.slug }/tools/move`)
        .setModel(Project)
        .setInjectValues({
          company_uuid: this.company.uuid
        })
        .setMethod(HttpMethod.PUT)
        .setFields([
          new Field()
            .setType(FieldTypes.datePicker)
            .setKey('date')
            .setEntryKey('date')
            .setTitle(__('company.views.tools.form.move.date'))
            .setMeta({
              max: moment().format('YYYY-MM-DD'),
            })
            .isRequired(),
          new SearchableField()
            .setKey('tool_uuids')
            .setTitle(__('company.views.tools.form.move.tools'))
            .loadItems({
              endpoint: `/company/${ this.company.slug }/tools`,
              value: 'uuid',
              title: 'name',
              perPage: 20,
              filters: [
                {
                  type: FilterOperators.nulled,
                  name: 'discarded_at',
                  value: true
                }
              ]
            })
            .isRequired()
            .isMultiple(),
          new SearchableField()
            .setKey('new_location_uuid')
            .setTitle(__('company.views.tools.form.move.move_location'))
            .loadItems({
              endpoint: `/company/${ this.company.slug }/tool-locations/autocomplete`,
              value: 'uuid',
              title: 'name',
              perPage: 20,
              filters: [
                {
                  type: FilterOperators.nulled,
                  name: 'deactivated_at',
                  value: true
                }
              ]
            })
            .isRequired(),
          new SearchableField()
            .setKey('employee_uuid')
            .setTitle(__('company.views.tools.move.employee'))
            .loadItems({
              endpoint: `/company/${ this.company.slug }/employees/autocomplete`,
              value: 'uuid',
              title: 'full_name',
              perPage: 20
            }),
          new Field()
            .setType(FieldTypes.textArea)
            .setKey('comment')
            .setTitle(__('company.views.tools.form.move.comment')),
        ])
      .setInitialValues({
        date: moment().format('YYYY-MM-DD')
      })
      .setOnSuccess(() => this.$router.push({ name: CompanyRoutes.toolsIndex }))
    }

    mounted() {
      if (this.form && this.$router.currentRoute.params.tools)
        this.form.data.tool_uuids = this.$router.currentRoute.params.tools.split(',')
    }

    goBack(): void {
      this.$router.back()
    }

    get company(): Company {
      return this.$store.getters[CompanyGetters.getCompany]
    }
  }
